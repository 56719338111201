<template>
  <b-card class="individually-trading-card">
    <div class="d-flex justify-content-between">
      <div class="text-left">
        <div class="limitString">{{ item._id }}</div>
        <h4>{{ item.name.split("-")[0] }}</h4>
      </div>
      <div class="text-right">
        <span>{{ $t("Tempo em operação") }}</span>
        <h4>
          {{ differenceDate(new Date(item.startDate)) }}
        </h4>
      </div>
    </div>
    <my-results-goal-overview :data="data.goalOverview" noHeader />

    <b-button block variant="warning" size="sm" class="mb-2" @click="() => allocateValue(item._id)"
      v-if="(item.licenseValue * 2.5) - item.initialValue > 0">
      {{ $t("Alocar valor para as operações") }}
    </b-button>

    <div class="d-md-flex w-100">
      <div class="w-100 mr-md-1">
        <b-button block variant="outline-success" class="mb-2" @click="() => bonusReinvest(item._id)">
          <div>{{ $t("Reinvestir os bônus") }}:</div>
          <div style="margin-top: 0.5rem;">{{ moneyFormat(this.item.bonus - this.item.totalWhitdraw) }}</div>
        </b-button>
      </div>
      <div class="w-100 ml-md-1">
        <b-button block variant="outline-info" class="mb-2"
          :disabled="isDatePlus15DaysGreater(this.item.initialValueDate)" @click="() => incomeReinvest(item._id)">
          <div>{{ $t("Reinvestir o rendimento") }}: </div>
          <div style="margin-top: 0.5rem;">{{ moneyFormat(this.item.totalProfit - this.item.totalIncomeWithdrawal) }}
          </div>
        </b-button>
      </div>
    </div>

    <div v-for="(robotInfo, index) in data.goalOverview.robotInfo" :key="index">
      <div :class="`d-flex justify-content-between ${robotInfo.marginTop && robotInfo.marginTop
        }`">
        <div>{{ robotInfo.title }}</div>
        <div>{{ robotInfo.value }}</div>
      </div>
    </div>
    <div class="text-center mt-2">
      <b-button block variant="success" size="sm" @click="() => $emit('withdraw', { item, type: 'bonus' })"
        :disabled="item.bonus - item.totalWhitdraw < 20">{{ $t("Retirar bônus de rede")
          + ' | ' + moneyFormat(item.bonus - item.totalWhitdraw)
        }}</b-button>
      <b-button block variant="primary" size="sm" @click="() => $emit('withdraw', { item, type: 'income' })"
        :disabled="item.totalProfit - item.totalIncomeWithdrawal <= 0 || getDayOfMonth()">{{ $t(`Retirar
        rendimentos`) +
          ' | ' + moneyFormat(item.totalProfit - item.totalIncomeWithdrawal)
        }}</b-button>
      <small class="text-warning">{{ $t('Saque do rendimento disponível somente nos dias 5 e 20 de cada mês') }}</small>
      <hr>
      <b-button variant="outline-primary" block size="sm" @click="() => openHistoric(item._id)">
        {{ $t('Ver registros das operações') }}
      </b-button>
    </div>

    <b-modal :id="'modal-scrollable-' + item._id" scrollable title="Registro das operações do robô" hide-footer
      size="lg" no-close-on-backdrop>
      <TableResults :data="item.ordersHistory" />
    </b-modal>

    <!-- Modal para adicionar fundos -->
    <b-modal :id="'modal-allocateValue-' + item._id" centered :title="$t('Adicionar Fundos')" hide-footer size="lg"
      no-close-on-backdrop>
      <div v-if="loadingPayment" class="text-center">
        {{ $t("Aguarde! Carregando...") }}
      </div>
      <div v-else>
        <div v-if="paymentDetails && paymentDetails.searchPendingPay">

          <div class="text-center mb-1">
            <h5>{{ $t("Tempo restante para pagamento:") }} <span class="text-danger">{{ countdown }}</span>
            </h5>
          </div>

          <div class="text-center">
            <h5>{{ $t("Valor a ser transferido:") }} <span class="text-primary">{{
              paymentDetails.searchPendingPay.value }} USDT</span></h5>
          </div>
          <div class="text-center text-warning"><small>{{ $t('1 USDT + 4% de Fee') }}</small></div>

          <div class="text-center my-2">
            <h5>{{ $t("Rede:") }} <span class="badge badge-warning">Binance Smart Chain (BSC)</span></h5>
          </div>

          <div class="text-center my-1">
            <h5 class="mb-0">{{ $t("Endereço de depósito:") }}</h5>
            <div class="d-flex justify-content-center align-items-center">
              <span :class="{ 'text-success': addressCopied, 'text-primary': !addressCopied }">
                {{ addressCopied ? $t("Endereço copiado") : compactAddress(paymentDetails.depositAddress) }}
              </span>
              <b-button variant="link" @click="copyToClipboard(paymentDetails.depositAddress)" class="ml-1">
                <i class="fas fa-copy"></i> {{ $t('Copiar') }}
              </b-button>
            </div>
          </div>


          <!-- Exibe o QR Code -->
          <div v-if="qrCodeData" class="text-center my-1">
            <h5>{{ $t("Escaneie o QR Code para abrir o endereço de depósito na sua carteira") }}:</h5>
            <img :src="qrCodeData" alt="QR Code" class="qr-code" />
          </div>

          <!-- Mensagem de Observação -->
          <div class="text-center mt-3">
            <p class="text-muted">{{ $t("Se você já fez o pagamento, aguarde a validação do sistema.") }}
            </p>
          </div>
        </div>
        <div v-else>
          <div>
            {{ $t('Digite abaixo o quanto você deseja investir') }}:
          </div>
          <b-form-input id="allocation-value" v-model="allocationValue" name="allocation-value" placeholder="$0.00"
            class="mt-1" type="number" />
          <b-alert variant="warning" class="mt-1">
            <div class="alert-body">
              <span class="text-warning">{{
                $t(
                  `Atenção: neste robô você pode alocar mais o valor de:`
                ) + ' ' + moneyFormat((item.licenseValue * 3) - item.initialValue)
                }}</span>
            </div>
          </b-alert>
          <b-button variant="primary" block class="mt-2" @click="allocateValueInTrading(item._id)"
            :disabled="btnLoading">
            {{ btnLoading ? $t("Aguarde...") : $t("Gerar endereço para depósito") }}
          </b-button>
          <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert" class="mt-2"
            @changeVariableAlert="(val) => (showAlert = val)" />
        </div>
      </div>
    </b-modal>

    <b-modal :id="'modal-bonusReinvest-' + item._id" centered title="Reinvestir o valor dos bônus" hide-footer size="lg"
      no-close-on-backdrop>
      <div>
        <div>
          {{
            $t(
              "Este é o valor de bônus disponível neste robô"
            )
          }}: <span class="text-warning">{{ moneyFormat(item.bonus - item.totalWhitdraw) }}</span>
        </div>
        <div>
          {{ $t('Digite abaixo o quanto você deseja reinvestir') }}:
        </div>
        <b-form-input id="allocation-value" v-model="allocationBonusValue" name="allocation-value" placeholder="$0.00"
          class="mt-1" type="number" />
        <b-alert variant="warning" class="mt-1">
          <div class="alert-body">
            <span class="text-warning">{{
              $t(
                `Atenção: neste robô você pode alocar mais o valor de:`
              ) + ' ' + moneyFormat((item.licenseValue * 3) - item.initialValue)
            }}</span>
          </div>
        </b-alert>
        <b-button variant="primary" block :disabled="btnLoading"
          @click="() => relocate(item._id, 'bonus', 'contribution')">
          {{ btnLoading ? $t('Aguarde...') : $t('Realocar valor nas operações deste robô') }}</b-button>
        <b-button variant="warning" block :disabled="btnLoading" @click="() => otherLicense = !otherLicense">{{
          $t('Realocar valor nas operações de outro robô') }}</b-button>
        <div class="p-2" v-if="otherLicense">
          <small>{{ $t('Selecione a licença que gostaria de realocar os bônus nas operações de trading') }}:</small>
          <b-form-radio v-model="licenseId" v-for="license in userLicenses" name="some-radios-2" :value="license._id"
            class="mr-1 mt-1">
            {{ license._id }} | {{ moneyFormat(license.licenseValue) }}
          </b-form-radio>
          <div class="d-flex justify-content-center mt-1">
            <b-button variant="warning" :disabled="!licenseId || btnLoading"
              @click="() => relocate(item._id, 'bonus', 'otherContribution')">{{ btnLoading ? $t('Aguarde...') :
                $t('Confirmar') }}</b-button>
          </div>
        </div>
        <b-button variant="success" block :disabled="btnLoading" @click="() => relocate(item._id, 'bonus', 'license')">
          {{ btnLoading ? $t('Aguarde...') : $t('Comprar nova licença') }}</b-button>
        <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert" class="mt-1"
          @changeVariableAlert="(val) => (showAlert = val)" />
      </div>
    </b-modal>

    <b-modal :id="'modal-incomeReinvest-' + item._id" centered title="Reinvestir o valor do rendimento" hide-footer
      size="lg" no-close-on-backdrop>
      <div>
        <div>
          {{
            $t(
              "Este é o valor de rendimento disponível neste robô"
            )
          }}: <span class="text-warning">{{ moneyFormat(item.totalProfit - item.totalIncomeWithdrawal) }}</span>
        </div>
        <div>
          {{ $t('Digite abaixo o quanto você deseja reinvestir') }}:
        </div>
        <b-form-input id="allocation-value" v-model="allocationIncomeValue" name="allocation-value" placeholder="$0.00"
          class="mt-1" type="number" />
        <b-alert variant="warning" class="mt-1">
          <div class="alert-body">
            <span class="text-warning">{{
              $t(
                `Atenção: neste robô você pode alocar mais o valor de:`
              ) + ' ' + moneyFormat((item.licenseValue * 3) - item.initialValue)
            }}</span>
          </div>
        </b-alert>
        <b-button variant="primary" block :disabled="btnLoading"
          @click="() => relocate(item._id, 'income', 'contribution')">
          {{ btnLoading ? $t('Aguarde...') : $t('Realocar valor nas operações deste robô') }}</b-button>
        <b-button variant="warning" block :disabled="btnLoading" @click="() => otherLicense = !otherLicense">{{
          $t('Realocar valor nas operações de outro robô') }}</b-button>
        <div class="p-2" v-if="otherLicense">
          <small>{{ $t('Selecione a licença que gostaria de realocar os rendimentos nas operações de trading')
            }}:</small>
          <b-form-radio v-model="licenseId" v-for="license in userLicenses" name="some-radios-2" :value="license._id"
            class="mr-1 mt-1">
            {{ license._id }} | {{ moneyFormat(license.licenseValue) }}
          </b-form-radio>
          <div class="d-flex justify-content-center mt-1">
            <b-button variant="warning" :disabled="!licenseId || btnLoading"
              @click="() => relocate(item._id, 'income', 'otherContribution')">{{ btnLoading ? $t('Aguarde...') :
                $t('Confirmar') }}</b-button>
          </div>
        </div>
        <b-button variant="success" block :disabled="btnLoading" @click="() => relocate(item._id, 'income', 'license')">
          {{ btnLoading ? $t('Aguarde...') : $t('Comprar nova licença') }}</b-button>
        <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert" class="mt-1"
          @changeVariableAlert="(val) => (showAlert = val)" />
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import MyResultsGoalOverview from "./MyResultsGoalOverview.vue";
import { format, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import TableResults from "./TableResults.vue";

import { BModal, BButton, VBModal, BAlert, BFormRadio } from "bootstrap-vue";
import { mapGetters } from "vuex";
import QRCode from "qrcode";

export default {
  components: {
    MyResultsGoalOverview,
    TableResults,
    BFormRadio
  },
  props: {
    item: Object,
  },
  data() {
    return {
      loadingPayment: false,
      paymentDetails: {
        searchPendingPay: {
          value: 0,
          crypto: '',
          createdAt: ''
        },
        depositAddress: ''
      },
      addressCopied: false,
      countdown: "00:00:00",
      qrCodeData: null,
      colorAlert: "warning",
      showAlert: false,
      textAlert: "",
      btnLoading: false,
      allocationValue: null,
      allocationBonusValue: null,
      allocationIncomeValue: null,
      daysRunning: 0,
      userLicenses: [],
      otherLicense: false,
      licenseId: null,
      data: {
        goalOverview: {
          initialValue: this.item.initialValue,
          licenseValue: this.item.licenseValue,
          totalProfit: this.item.totalProfit,
          bonus: this.item.bonus,
          currentValue: this.item.totalProfit + this.item.bonus,
          series: [
            this.item.limitIncome > 0 ? parseFloat(
              ((this.item.totalProfit + this.item.bonus * 0.2) / this.item.limitIncome) * 100
            ).toFixed(2) : 0,
          ],
          progressValue: parseFloat((this.item.bonus / this.item.limitBonus) * 100).toFixed(2),
          robotInfo: [
            {
              title: this.$i18n.t("Adquiriu a licença em"),
              value: this.format(new Date(this.item.startDate), "dd/MM/yyyy HH:mm"),
            },
            {
              title: this.$i18n.t("Valor da licença"),
              value: this.moneyFormat(this.item.licenseValue),
            },
            {
              title: this.$i18n.t("Data do aporte"),
              value: this.item.initialValueDate ? this.format(new Date(this.item.initialValueDate), "dd/MM/yyyy HH:mm") : '--',
            },
            {
              title: this.$i18n.t("Valor do aporte"),
              value: this.item.initialValue ? this.moneyFormat(this.item.initialValue) : this.moneyFormat(0),
            },
            {
              title: this.$i18n.t("Total de trades"),
              value: this.item.trade,
              marginTop: "mt-1",
            },
            { title: this.$i18n.t("Trades lucrativos"), value: this.item.gain },
            {
              title: this.$i18n.t("Trades com prejuízo"),
              value: this.item.loss,
            },
            {
              title: this.$i18n.t("Recebido de rendimentos"),
              value: this.moneyFormat(this.item.totalProfit),
              marginTop: "mt-1",
            },
            {
              title: this.$i18n.t("Recebido de bonus"),
              value: this.moneyFormat(this.item.bonus),
            },
            {
              title: this.$i18n.t("Valor disponível para retirada"),
              value: this.moneyFormat(this.item.totalProfit + this.item.bonus - this.item.totalWhitdraw - this.item.totalIncomeWithdrawal),
              marginTop: "mt-1",
            },
            {
              title: this.$i18n.t("Valor retirado de bônus"),
              value: this.moneyFormat(this.item.totalWhitdraw),
            },
            {
              title: this.$i18n.t("Valor retirado de rendimentos"),
              value: this.moneyFormat(this.item.totalIncomeWithdrawal),
            },
          ],
        },
      },
    };
  },
  computed: mapGetters(["returnUser"]),
  mounted() {
    this.getUserLicenses()
  },
  methods: {
    compactAddress(address) {
      // Exibir os primeiros 6 e os últimos 4 caracteres do endereço
      return address ? `${address.slice(0, 10)}...${address.slice(-10)}` : '';
    },
    copyToClipboard(address) {
      navigator.clipboard.writeText(address).then(() => {
        this.addressCopied = true;
        setTimeout(() => {
          this.addressCopied = false;
        }, 3000); // Após 3 segundos, retorna o endereço
        this.$bvToast.toast(this.$t('Endereço copiado!'), {
          title: this.$t('Sucesso'),
          variant: 'success',
          solid: true,
        });
      }).catch(err => {
        // console.error('Erro ao copiar endereço:', err);
      });
    },
    isDatePlus15DaysGreater(datePassed) {
      // Convert the passed string date to a Date object
      const initialDate = new Date(datePassed);

      // Add 15 days to the initial date
      initialDate.setDate(initialDate.getDate() + 15);

      // Get the current date
      const currentDate = new Date();

      // Return true if the new date is greater than the current date
      return initialDate > currentDate;
    },
    getUserLicenses() {
      this.$store
        .dispatch("getUserLicense", this.returnUser._id).then((resp) => {
          this.userLicenses = resp;
        })
    },
    openHistoric(id) {
      this.$bvModal.show(`modal-scrollable-${id}`);
    },
    allocateValue(id) {
      this.$bvModal.show(`modal-allocateValue-${id}`);
      this.getDepositIntent(id);
    },
    bonusReinvest(id) {
      this.$bvModal.show(`modal-bonusReinvest-${id}`);
    },
    incomeReinvest(id) {
      this.$bvModal.show(`modal-incomeReinvest-${id}`);
    },
    differenceDate(date) {
      // Criando uma nova data com a string fornecida
      const dataFornecida = new Date(date);

      // Obtendo a data atual
      const dataAtual = new Date();

      // Calculando a diferença em milissegundos
      const diferencaEmMilissegundos = dataAtual - dataFornecida;

      // Convertendo para dias, horas e minutos
      const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
      const umHoraEmMilissegundos = 1000 * 60 * 60;
      const umMinutoEmMilissegundos = 1000 * 60;

      const dias = Math.floor(diferencaEmMilissegundos / umDiaEmMilissegundos);
      const horas = Math.floor(
        (diferencaEmMilissegundos % umDiaEmMilissegundos) /
        umHoraEmMilissegundos
      );
      const minutos = Math.floor(
        (diferencaEmMilissegundos % umHoraEmMilissegundos) /
        umMinutoEmMilissegundos
      );

      // Adicionando zeros à esquerda para horas e minutos menores que 10
      const horasComZero = horas < 10 ? `0${horas}` : `${horas}`;
      const minutosComZero = minutos < 10 ? `0${minutos}` : `${minutos}`;

      // Imprimindo a diferença em dias, horas e minutos
      return `${dias} dias, ${horasComZero}h:${minutosComZero}m`;
    },
    getDayOfMonth() {
      var date = new Date();
      var day = date.getDate();
      if (day !== 5 && day !== 20) {
        return true;
      }
    },
    relocate(id, relocate, to) {
      this.btnLoading = true
      const data = {
        robotId: id,
        value: relocate === 'bonus' ? this.allocationBonusValue : this.allocationIncomeValue,
        relocate: relocate,
        to: to
      }
      if (to === 'otherContribution') {
        data.licenseId = this.licenseId
      }
      this.$store
        .dispatch("relocateValue", data)
        .then((resp) => {
          this.showAlert = true;
          this.colorAlert = "success";
          this.textAlert = this.$i18n.t("Novo valor reinvestido");
          this.$emit("reloadRobots");
          setTimeout(() => { this.$bvModal.hide(`modal-allocateValue-${id}`); this.btnLoading = false }, 5000);
        })
        .catch((error) => {
          this.btnLoading = false
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = error.message;
        });
    },
    getDepositIntent(id) {
      this.loadingPayment = true;
      const data = {
        planId: id
      };
      this.$store
        .dispatch("getDepositIntent", data)
        .then((resp) => {
          this.loadingPayment = false;
          if (resp && resp.searchPendingPay) {
            this.paymentDetails = resp;
            this.startCountdown(this.paymentDetails.searchPendingPay.createdAt); // Inicia a contagem regressiva
            this.generateQRCode(); // Gera o QR Code
          } else {
            console.error('Dados de pagamento incompletos:', resp);
          }
        })
        .catch((err) => {
          this.loadingPayment = false;
          // console.error('Erro ao buscar detalhes do pagamento:', err);
          this.paymentDetails = {}; // Certifique-se de limpar dados incorretos
        });
    },
    startCountdown(targetDate) {
      // Converte a data de entrada para um objeto Date e adiciona 1 hora
      let target = new Date(targetDate);
      target.setMinutes(target.getMinutes() + 10);

      // Função que atualiza a contagem regressiva
      const updateCountdown = () => {
        const now = new Date().getTime();
        const timeDifference = target.getTime() - now;

        if (timeDifference > 0) {
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

          this.countdown = `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        } else {
          this.countdown = "00:00:00";
          clearInterval(countdownInterval);
        }
      };

      // Atualiza a cada segundo
      const countdownInterval = setInterval(updateCountdown, 1000);
      updateCountdown();
    },
    generateQRCode() {
      const depositAddress = this.paymentDetails.depositAddress;
      const value = this.paymentDetails.searchPendingPay.value;

      // USDT BEP-20 contract address on BSC
      const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';

      // Correct URI for USDT transfer on Binance Smart Chain
      const uri = `ethereum:${depositAddress}`;

      // Generate the QR code using the qrcode library
      QRCode.toDataURL(uri)
        .then((url) => {
          this.qrCodeData = url; // Save the QR code URL for display
        })
        .catch((err) => {
          // console.error(err);
        });
    },
    allocateValueInTrading(id) {
      this.btnLoading = true
      const data = {
        robotId: id,
        value: this.allocationValue
      }
      this.$store
        .dispatch("allocateValueInTrading", data)
        .then(() => {
          this.btnLoading = false;
          this.getDepositIntent(id);
        })
        .catch((error) => {
          this.btnLoading = false;
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = error.message;
        });
    },
  },
  setup() {
    formatDistanceToNow;
    format;
    ptBR;

    return {
      formatDistanceToNow,
      format,
      ptBR,
    };
  },
};
</script>

<style scoped>
.individually-trading-card {
  margin-top: 2.14rem;
}

@media only screen and (max-width: 768px) {
  .limitString {
    white-space: nowrap;
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>