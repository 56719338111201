<template>
  <b-card v-if="data" no-body class="card-developer-meetup">
    <div class="bg-light-primary rounded-top text-center">
      <!-- <b-img
        :src="require('@/assets/images/business_plan/banner-rede.png')"
        alt="Meeting Pic"
        fluid
      /> -->
    </div>
    <b-card-body>
      <div class="mb-3">
        <b-card-title>{{ $t("Bônus de rede") }}</b-card-title>
      </div>
      <!-- metting header -->
      <div class="d-flex justify-content-center">
        <b-button variant="primary" @click="copyReferenceLink">{{ $t('Copiar link de convite') }}</b-button>
      </div>
      <!--/ metting header -->

      <!-- media -->
       <div class="mt-3">
         <b-media v-for="media in data.mediaData" :key="media.avatar" no-body class="mt-2">
           <b-media-aside class="mr-1">
             <b-avatar rounded :variant="media.color == 'success' ? 'light-primary' : 'light-danger'" size="34">
               <feather-icon :icon="media.avatar" size="18" />
             </b-avatar>
           </b-media-aside>
           <b-media-body>
             <h6 class="mb-0">
               {{ media.title }}
             </h6>
             <small>{{ media.subtitle }}</small>
           </b-media-body>
         </b-media>
       </div>

       <div class="mt-3">
        <div>
          {{ $t('Meu patrocinador:') }} <span class="text-primary">{{ upline.fullname }}</span>
        </div>
        <div>
          {{ $t('E-mail do patrocinador:') }} <span class="text-primary">{{ upline.email }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import copy from "copy-text-to-clipboard";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters(["returnUser", "returnLocale"]),
  },
  data() {
    return {
      referenceLink: "",
      referenceBonus: 0,
      upline: {}
    };
  },
  mounted() {
    this.referenceLink =
      process.env.VUE_APP_FRONT_URL + "/ref/" + this.returnUser._id;
    this.referenceBonus = this.returnUser.referenceBonus;
    this.myUpline();
  },
  methods: {
    myUpline() {
      this.$store
        .dispatch("myUpline")
        .then((resp) => {
          this.upline = resp;
        })
    },
    copyReferenceLink() {
      let copyText = this.referenceLink;
      copy(copyText);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "CoffeeIcon",
          variant: "info",
          title: this.$i18n.t(
            "Link de convite copiado!"
          )
        },
      });
    },
  },
};
</script>

<style>
.limitString {
  white-space: nowrap;
  max-width: 22rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
