<template>
  <div>
    <b-table small :fields="fields" :items="items" responsive="sm" class="mt-1">
      <template #cell(date)="data">
        {{ formatDate(data.item.date) }}
      </template>

      <template #cell(active)="data">
        {{ data.item.active }}
      </template>

      <template #cell(type)="data">
        {{ $t(data.item.type) }}
      </template>

      <template #cell(action)="data">
        {{ $t(data.item.buySell) }}
      </template>

      <template #cell(price)="data">
        <div>
          {{ moneyFormat(data.item.price) }}
        </div>
      </template>

      <template #cell(percent)="data">
        <span :class="data.item.percent > 0 ? 'text-success' : data.item.percent < 0 && 'text-danger'">
          {{ parseFloat(data.item.percent).toFixed(2) }}%
        </span>
      </template>
    </b-table>
  </div>
</template>
  
<script>
import { BTable, BProgress, BBadge } from 'bootstrap-vue'
const { format } = require('date-fns');

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
  },
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      fields: [
        { key: 'date', label: this.$i18n.t('Data / Hora') },
        { key: 'active', label: this.$i18n.t('Ativo') },
        { key: 'type', label: this.$i18n.t('Tipo') },
        { key: 'action', label: this.$i18n.t('Operação') },
        { key: 'price', label: this.$i18n.t('Preço') },
        { key: 'percent', label: this.$i18n.t('Resultado') },
      ],
      items: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.items = this.data;
    });
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm');
    },
  }
}
</script>