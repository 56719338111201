<template>
  <b-card no-body class="card-browser-states">
    <b-card-header class="mb-2">
      <div>
        <b-card-title>{{ $t("Informações gerais") }}</b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Robôs adquiridos") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{
            general ? general.amountBots : 0
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Em operação") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{
            general ? general.botsRunning : 0
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Valor total alocado") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-warning">{{
            moneyFormat(general ? general.initialValue : 0)
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Lucro total registrado") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-success">+ {{
            moneyFormat(general ? general.totalProfit : 0)
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Total recebido de bônus") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-success">+ {{
            moneyFormat(general ? general.bonus : 0)
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Total em retiradas") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-danger">- {{
            moneyFormat(general ? general.totalWhitdraw : 0)
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Valor total em licenças") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-danger">- {{
            moneyFormat(general ? general.licenseValue : 0)
          }}</span>
        </div>
      </div>
      <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Disponível para retirar") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-info">{{
            moneyFormat(general ? general.totalProfit + general.bonus - general.totalWhitdraw : 0)
          }}</span>
        </div>
      </div>
      <!-- <div class="browser-states">
        <h6 class="align-self-center my-auto">
          {{ $t("Saldo atual dos robôs") }}
        </h6>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1 text-info">{{
            moneyFormat(general ? general.currentValue : 0)
          }}</span>
        </div>
      </div> -->
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { format, formatDistanceToNow } from "date-fns";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
  },
  props: {
    general: Object,
  },
  computed: mapGetters(["returnUser"]),
  data() {
    return {
      browserData: [
        {
          name: this.$i18n.t("Robôs adquiridos"),
          usage: this.general?.acquiredRobots,
        },
        {
          name: this.$i18n.t("Em operação"),
          usage: this.general ? this.general.runningRobots : 0,
        },
        {
          name: this.$i18n.t("Total em retiradas"),
          usage: this.moneyFormat(this.general ? this.general.totalWithdrawalsFromRobots : 0),
        },
        {
          name: this.$i18n.t("Depósitos"),
          usage: this.moneyFormat(this.general ? this.general.deposits : 0),
        },
        {
          name: this.$i18n.t("Saques"),
          usage: this.moneyFormat(this.general ? this.general.totalInWithdrawals : 0),
        },
        {
          name: this.$i18n.t("ROI"),
          usage: "0%",
        },
      ],
    };
  },
  setup() {
    format, formatDistanceToNow;

    return {
      format,
      formatDistanceToNow,
    };
  },
};
</script>
