<template>
  <b-card v-if="data" class="card-congratulation-medal">
    <h5 class="limitString">{{ $t("Parabéns") }} 🎉 {{ data.name }}</h5>
    <b-card-text class="font-small-3" style="max-width: 16rem">
      {{
        $t(
          "Você é um trader de nível ouro. Você está entre os 10% maiores traders da plataforma."
        )
      }}
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      <div style="font-size: 0.9rem">{{ $t("Total: Rendimento + Bônus") }}:</div>
      <div class="text-primary" style="margin-top: 0.3rem">
        {{
          moneyFormat(data.patrimony)
        }}
      </div>
    </h3>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
};
</script>

<style scoped>
.limitString {
  white-space: nowrap;
  max-width: 18rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
