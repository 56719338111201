<template>
  <b-card v-if="data" no-body>
    <b-card-header v-if="!noHeader">
      <h4 class="mb-0">
        {{ $t("Evolução") }}
      </h4>
    </b-card-header>

    <!-- apex chart -->
    <div class="text-center mt-2" style="margin-bottom: -1rem;">
      {{ $t('Teto de rentabilidade') }}
     </div>
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="data.series[0] == 'NaN' ? [0] : data.series"
    />
    <div class="px-2 mb-1">
      <!-- Barra de progresso -->
      <div style="margin-bottom: 0.5rem;">
      {{ $t('Teto de rede') }}
     </div>
      <b-progress :value="data.progressValue * 2" max="200" height="16px">
        <b-progress-bar :value="data.progressValue * 2" variant="success">
          {{ data.progressValue * 2 }}%
        </b-progress-bar>
      </b-progress>
    </div>
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="
          border-top border-right
          d-flex
          align-items-between
          flex-column
          py-1
        "
      >
        <b-card-text class="text-muted mb-0">
          {{ $t("Total investido") }}
          <div>
            <small>
              {{ $t('licença + aporte') }}
            </small>
          </div>
        </b-card-text>
        <h4 class="font-weight-bolder mb-0 mt-1">
          {{ moneyFormat(data.initialValue + data.licenseValue) }}
        </h4>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t("Retorno total") }}
          <div>
            <small>
              {{ $t('rendimento + bônus') }}
            </small>
          </div>
        </b-card-text>
        <h4 class="font-weight-bolder mb-0 mt-1">
          {{ moneyFormat(data.bonus + data.totalProfit) }}
        </h4>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText, BProgress, BProgressBar } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#51e5a8";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BProgress,
    BProgressBar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      progressValue: 50, // valor de exemplo, você deve ajustá-lo de acordo com seus dados
    };
  },
};
</script>
