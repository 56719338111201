<template>
  <div>
    <vue-slider v-model="whitdrawValue" :max="balance" disabled />
    <b-form-input
      id="allocation-value"
      v-model="whitdrawValue"
      name="allocation-value"
      placeholder="$0.00"
      class="mt-1"      
    />
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  components: {
    VueSlider,
  },
  props: {
    balance: Number,
    percentWithdraw: Number,
    profit: Number,
  },
  data() {
    return {
      whitdrawValue: 0,
    };
  },
  watch: {
    whitdrawValue: function (newVal) {
      this.$emit("changeWhitdrawValue", newVal);
    },
    balance: function (newVal) {
      this.whitdrawValue = 0;
    },
    percentWithdraw: function (newVal) {
      if (newVal > 1) {
        this.whitdrawValue = this.profit > 0 ? this.profit : 0;
      } else {
        this.whitdrawValue = this.balance * newVal;
      }
    },
  },
};
</script>